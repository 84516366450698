import React from "react";

import "./ProjectDetailStyles.css";
import "../components/ResponsiveStyles.css";

import NavbarTop from "../components/NavbarTop";
import Footer from "../components/Footer";

import Multi from "../assets/projectsPics/Multi-Storey.png";

const MultiStorey = () => {
  return (
    <div className="bodyContentTop">
      <NavbarTop />
      <div className="content">
        <div className="contentDetail">
          <h2>Multi-Storey Residential Complex</h2>
          <img src={Multi} alt="" />

          <div className="boxFlexDetail">

            <div className="boxDetail">
              <h4>Client</h4>
              <p>Shaikh Ahmed Jassim Bin Fahad Al Thani</p>
            </div>

            <div className="boxDetail">
              <h4>Location</h4>
              <p>Al Sadd, Qatar</p>
            </div>

            <div className="boxDetail">
              <h4>Completion Date</h4>
              <p>September 2009</p>
            </div>

            <div className="boxDetail">
              <h4>Contract Value</h4>
              <p>63,500,000.00 QAR</p>
            </div>

          </div>

          <div className="boxDetailS">
            <h4>Scope of Work</h4>
            <p>Construction of B+G+M+4 flats residential complex; including all the relevant structural, architectural, interior finishes, MEP installation and external services including hard landscaping</p>
          </div>

        </div>
      </div>

      <Footer />
    </div>
  );
};

export default MultiStorey;