
import "./NavbarStyles.css";

import React, { useState } from "react";
import LogoImg from "../assets/ArconLogo.png";
import LogoImgWhite from "../assets/ArconLogoWhite.png";

import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { FaLinkedin, FaFacebook, FaInstagram, FaXTwitter  } from "react-icons/fa6";

const Navbar = () => {


    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    const [color, setColor] = useState(false);
    const changeColor = () => {
        if (window.scrollY >= 100) {
            setColor(true);
        } else {
            setColor(false);
        }
    };

    window.addEventListener("scroll", changeColor);

    return (
        <div className={color ? "nav-container nav-container-bg" : "nav-container"}>
            <div className="header-container">
                <Link to="/">
                    <div className="logo">
                        <img className="logo-img" src={LogoImg} alt="LogoImg" />
                        <img className="logo-imgWhite" src={LogoImgWhite} alt="LogoImg" />
                    </div>
                </Link>
                <ul className={click ? "nav-menu active" : "nav-menu"}>
                    <div className="MobileNav">
                        <img src={LogoImg} alt="LogoImg" />
                    </div>

                    <li>
                        <a><Link to="/">HOME</Link></a>
                    </li>

                    <li>
                        <a><Link to="/About">ABOUT</Link></a>
                    </li>

                    <li>
                        <a><Link to="/Projects">PROJECTS</Link></a>
                    </li>

                    <li>
                        <a><Link to="/Contact">CONTACT</Link></a>
                    </li>

                    <div className="NavFollow">
                        <div className="NavFollowflex">
                        <a><FaLinkedin size={20}> </FaLinkedin></a>
                        <a><FaFacebook size={20}></FaFacebook></a>
                        <a><FaInstagram size={20}></FaInstagram></a>
                        <a><FaXTwitter size={20}></FaXTwitter></a>
                        </div>
                    </div>
                </ul>

                <div className="hamburger" onClick={handleClick}>
                    {click ? (
                        <FaTimes className="menuBTNclose" />
                    ) : (
                        <FaBars className="menuBTNopen" />
                    )}
                </div>
            </div>


        </div>

    );
};

export default Navbar;
