import React from 'react'
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';

import { FaDownload } from "react-icons/fa6";


import "./HeroSliderStyles.css";

import img1 from "../assets/cournishe.jpg";
import img2 from "../assets/GrandHyattBeach.jpg";
import img3 from "../assets/KhalifaStadium.png";
import img4 from "../assets/hamadcity.png";

import pdf from "../ARCONProfile.pdf"


function HeroSliderHome() {
    return (
        <>
            <div className="container">
                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Autoplay, Navigation]}
                    className="mySwiper"
                >
                    <SwiperSlide><img src={img1} alt="" /></SwiperSlide>
                    <SwiperSlide><img src={img2} alt="" /></SwiperSlide>
                    <SwiperSlide><img src={img3} alt="" /></SwiperSlide>
                    <SwiperSlide><img src={img4} alt="" /></SwiperSlide>
                </Swiper>
                <div className='contentHeroTitle'>
                    <div className="contentTitle">
                        <h1>Trustworthy<br/>
                            Growth Partner<br/>
                            in Qatar<br/>
                        </h1>
                    </div>
                </div>

                <div className='contentdownloadButtom'>
                        <p><Link to={pdf} target="_blank" rel="noopener noreferrer" className='downloadButtom'><FaDownload/> DOWNLOAD COMPANY PROFILE</Link></p>

                </div>

            </div>

        </>
    );
};
export default HeroSliderHome