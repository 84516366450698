import React from "react";

import "./ProjectDetailStyles.css";
import "../components/ResponsiveStyles.css";

import NavbarTop from "../components/NavbarTop";
import Footer from "../components/Footer";

import UmSalal from "../assets/projectsPics/UmSalalSocial.png";

const UmSalalSocial = () => {
  return (
    <div className="bodyContentTop">
      <NavbarTop />
      <div className="content">
        <div className="contentDetail">
          <h2>Social & Cultural Centre</h2>
          <img src={UmSalal} alt="" />

          <div className="boxFlexDetail">

            <div className="boxDetail">
              <h4>Client</h4>
              <p>Qatar Olympic Committee</p>
            </div>

            <div className="boxDetail">
              <h4>Location</h4>
              <p>Umm Salal, Qatar</p>
            </div>

            <div className="boxDetail">
              <h4>Completion Date</h4>
              <p>February 2009</p>
            </div>

            <div className="boxDetail">
              <h4>Contract Value</h4>
              <p>8,900,000.00 QAR</p>
            </div>

          </div>

          <div className="boxDetailS">
            <h4>Scope of Work</h4>
            <p>Construction, completion and maintenance of multi purpose sports hall and the attached buildings, services, facilities, roads, parking & boundary wall with all the relevant architectural, structural and MEP installation</p>
          </div>

        </div>
      </div>

      <Footer />
    </div>
  );
};

export default UmSalalSocial;