import React, { useEffect } from "react";

import { Route, Routes } from "react-router-dom";

import Home from "./Home";
import About from "./About";
import Projects from "./Projects";
import Contact from "./Contact";

import NewtonSchool from "./ProjectsPages/NewtonSchool";
import CourtBuilding from "./ProjectsPages/CourtBuilding";
import CarParkingHMC from "./ProjectsPages/CarParkingHMC";
import MicBusiness from "./ProjectsPages/MicBusiness";
import ABMMilitary from "./ProjectsPages/ABMMilitary";
import CulturalSocialCenter from "./ProjectsPages/CulturalSocialCenter";
import DentalClinics from "./ProjectsPages/DentalClinics";
import Laboratory from "./ProjectsPages/Laboratory";
import MultiStorey from "./ProjectsPages/MultiStorey";
import SchoolGirl from "./ProjectsPages/SchoolGirl";
import UmSalalSocial from "./ProjectsPages/UmSalalSocial";
import DukhanTownship from "./ProjectsPages/DukhanTownship";
import EmergncyHMC from "./ProjectsPages/EmergncyHMC";
import LusailDevelopment from "./ProjectsPages/LusailDevelopment";
import HamadEducationCenter from "./ProjectsPages/HamadEducationCenter";
import PrivateVillaAlWajba from "./ProjectsPages/PrivateVillaAlWajba";
import Villas from "./ProjectsPages/Villas";
import DiwanAmiriLibrary from "./ProjectsPages/DiwanAmiriLibrary";
import SixResidentalBuilding from "./ProjectsPages/SixResidentalBuilding";

import ScrollToTop from "./components/ScrollToTop";

function App() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>

      <div className="App">
        {<Routes>
        <Route onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }} exact path="/" element={<Home />} />
        <Route onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }} path="/About" element={<About />} />
        <Route onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }} path="/Projects" element={<Projects />} />
        <Route onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }} path="/Contact" element={<Contact />} />

        <Route path="/Projects/NewtonSchool" element={<NewtonSchool />} />
        <Route path="/Projects/CourtBuilding" element={<CourtBuilding />} />
        <Route path="/Projects/CarParkingHMC" element={<CarParkingHMC />} />
        <Route path="/Projects/MicBusiness" element={<MicBusiness />} />
        <Route path="/Projects/ABMMilitary" element={<ABMMilitary />} />
        <Route path="/Projects/CulturalSocialCenter" element={<CulturalSocialCenter />} />
        <Route path="/Projects/DentalClinics" element={<DentalClinics />} />
        <Route path="/Projects/Laboratory" element={<Laboratory />} />
        <Route path="/Projects/MultiStorey" element={<MultiStorey />} />
        <Route path="/Projects/SchoolGirl" element={<SchoolGirl />} />
        <Route path="/Projects/UmSalalSocial" element={<UmSalalSocial />} />
        <Route path="/Projects/DukhanTownship" element={<DukhanTownship />} />
        <Route path="/Projects/EmergncyHMC" element={<EmergncyHMC />} />
        <Route path="/Projects/LusailDevelopment" element={<LusailDevelopment />} />
        <Route path="/Projects/HamadEducationCenter" element={<HamadEducationCenter />} />
        <Route path="/Projects/PrivateVillaAlWajba" element={<PrivateVillaAlWajba />} />
        <Route path="/Projects/Villas" element={<Villas />} />
        <Route path="/Projects/DiwanAmiriLibrary" element={<DiwanAmiriLibrary />} />
        <Route path="/Projects/SixResidentalBuilding" element={<SixResidentalBuilding />} />

      </Routes>}
        <ScrollToTop />
      </div>

      

    </>
  );
}

export default App;
