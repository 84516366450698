import React from "react";

import "./AboutStyles.css";
import "./components/ResponsiveStyles.css";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import TopRespon from "./components/TopRespon";

import TopBgAbt from "./assets/TopBgAbt.jpg";


const About = () => {
  return (
    <div className="bodyContent">
      <Navbar />
      <div className="container">

        <div className="topBackground">
          <img src={TopBgAbt} alt="" />
          <div className="content">
            <div className="pageTitle">
              <h1>About Us</h1>
            </div>
          </div>
        </div>

      </div>
      <div className="content">
        <div className="contentAbt">
          <h1>Building Qatar’s Future, Today</h1>

          <p><b>Arab United Construction W.L.L. (ARCON)</b> is a local Qatari company, which came into existence in the year 2001, and was formerly known as <b>Gulf Falcon Engineering</b>, operating since 1991.</p>
          <p>Arab United Construction W.L.L, widely known as “ARCON” in abbreviation, is one of the leading construction contracting companies in Doha, Qatar. Arcon is accredited with Grade ‘A’ civil construction company by CTC and MMAA. It is a company with Integrated Management System maintained with ISO certifications (ISO 9001:2015, ISO 14001:2015, ISO 45001:2018, ISO 27001:2013), and also an associated member with U.S. Green Building Council.</p>
          <p>ARCON is a professionally managed company with having adopted & maintained professional and systematic approach as per national & international construction methods, and having on its permanent rolls highly qualified, experienced Heads of Departments, legal advisor, PRO’s, sufficient numbers of Civil Engineers, Architects, Mechanical Engineers, Electrical Engineers, Planning Engineers, QA/QC Engineers,  SE Engineers, Estimators, Quantity Surveyors, Internal Auditors, Accounts & Accounting Staff, Technical Staff, Draughtsman, Foremen/supervisors, Technicians, and a large number of skilled and semi-skilled labor force 649 in total.</p>

          <p><b>ARCON’s main stream of business in engineering & construction of Medium Scale, Large and Mega scale (Turnkey basis) projects such as:</b></p>
          <p>
            • Commercial buildings complexes<br />
            • Residential Complexes<br />
            • Recreational and Sports Facilities/Arenas<br />
            • Education Institutions<br />
            • Medical Facilities (such as hospitals, Health Centers)<br />
            • Residential Villas<br />
            • High-rise buildings<br />
            • Renovation /Restoration /Refurbishment of Civil Structures<br />
            • Construction of Infrastructure projects<br />
            • Construction and maintenance of Waterworks</p>

          <p><b>Major Functional Departments under ARCON Organization:</b></p>
          <p><b>1.</b> Commercial buildings/complexes<br />
            • Pre-Contract/Tendering Division<br />
            • Post-Contract/Construction Division<br />

          </p>

          <p><b>2.</b> Projects Execution Department (comprising Civil & MEP)<br />
            • Field Support Divisions:<br />
            - Project Execution Team<br />
            - Project Technical Office<br />
            - Project QA/QC<br />
            - Project HSE & Security<br />
            - Project Resource<br />
            - Project Procurement/Logistic<br />
            - Project Planning<br />
          </p>

          <p><b>3.</b> Administration Department</p>

          <p><b>4.</b> Finance Department</p>

          <p><b>5.</b> QA/QC Department</p>

          <p><b>6.</b> Health, Safety and Environment Department</p>

          <p><b>7.</b> Human Resource Department</p>

          <p><b>8.</b> Procurement and Logistic Department</p>

          <p><b>9.</b> Legal Department</p>

          <p><b>10.</b> Internal Audit & Control Department</p>

          <div className="abtTitle">
            <h2>Mission & Vision</h2>
          </div>
          <p><b>Our Mission</b></p>
          <p>ARCON has maintained its stable growth and is confident of its resourceful and cash flow capabilities in the years to come, sharing the vision of Qatar to be the premiere and world class leader not only in economy but also as a diversified society by providing high standard of living.</p>
          <p>ARCON aims towards the aptness of its efforts to share Qatar’s vision and aid the state in rendering the highest standard in its construction projects. To safely deliver any construction project on time, budget and with high quality standards while respecting the local physical and social environment.</p>
          <p><b>Our Vision</b></p>
          <p>To seek excellence in revolutionizing the construction industry by achieving new standards of Integrated Management System and productivity that are significantly above current industry standards.</p>

        </div>



      </div>

      <Footer />
    </div>
  );
};

export default About;