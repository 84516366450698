import React from "react";

import "./ProjectDetailStyles.css";
import "../components/ResponsiveStyles.css";

import NavbarTop from "../components/NavbarTop";
import Footer from "../components/Footer";

import Emergncy from "../assets/projectsPics/DukhanTownship.png";

const EmergncyHMC = () => {
  return (
    <div className="bodyContentTop">
      <NavbarTop />
      <div className="content">
        <div className="contentDetail">
          <h2>Emergency Medical Services & Health Center</h2>
          <img src={Emergncy} alt="" />

          <div className="boxFlexDetail">

            <div className="boxDetail">
              <h4>Client</h4>
              <p>Hamad Medical Corporation (HMC)</p>
            </div>

            <div className="boxDetail">
              <h4>Location</h4>
              <p>Muraikh, Qatar</p>
            </div>

            <div className="boxDetail">
              <h4>Completion Date</h4>
              <p>May 2007</p>
            </div>

            <div className="boxDetail">
              <h4>Contract Value</h4>
              <p>23,400,000.00 QAR</p>
            </div>

          </div>

          <div className="boxDetailS">
            <h4>Scope of Work</h4>
            <p>Construction of EMS Type B (2 Floors) and Health Center Ambulance Parking, Medical Gases Plant Room, Substation Block, Tank yard Area, Guardroom, Qtel Room, 21 Nos. Covered Car Parking, 2 Nos. Handicapped Car Parking, 40 Nos. Not Covered Car parking, 3 Nos. Ambulance Parking & 3 Nos Plant Room Vehicle Parking; including all The Relevant Structural, Architectural, Interior Finishes, MEP Installation Soft and Hard Landscaping and External Services</p>
          </div>

        </div>
      </div>

      <Footer />
    </div>
  );
};

export default EmergncyHMC;