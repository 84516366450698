import React from "react";

import "./ProjectDetailStyles.css";
import "../components/ResponsiveStyles.css";

import NavbarTop from "../components/NavbarTop";
import Footer from "../components/Footer";

import Dental from "../assets/projectsPics/DentalClinics.jpg";

const DentalClinics = () => {
  return (
    <div className="bodyContentTop">
      <NavbarTop />
      <div className="content">
        <div className="contentDetail">
          <h2>Dental Clinics</h2>
          <img src={Dental} alt="" />

          <div className="boxFlexDetail">

            <div className="boxDetail">
              <h4>Client</h4>
              <p>Hamad Medical Corporation (HMC)</p>
            </div>

            <div className="boxDetail">
              <h4>Location</h4>
              <p>Al Wakrah, Qatar</p>
            </div>

            <div className="boxDetail">
              <h4>Completion Date</h4>
              <p>November 2010</p>
            </div>

            <div className="boxDetail">
              <h4>Contract Value</h4>
              <p>29,540,099.95 QAR</p>
            </div>

          </div>

          <div className="boxDetailS">
            <h4>Scope of Work</h4>
            <p>• Omran Bin Khatab Dental Clinics - Construction of main Building, Prayer Room, Pump Room, Generator Room and External Works</p>
            <p>• Al Wakrah Health Center - Construction of main Building, Service Block and External Works</p>     

          </div>

        </div>
      </div>

      <Footer />
    </div>
  );
};

export default DentalClinics;