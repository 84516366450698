import React from 'react';

import { Link } from "react-router-dom";

import "./ContactStyles.css";
import "./components/ResponsiveStyles.css";

import NavbarTop from "./components/NavbarTop";
import Footer from "./components/Footer";

import {

  FaMailBulk,

} from "react-icons/fa";

import { FaLinkedin, FaFacebook, FaInstagram, FaXTwitter } from "react-icons/fa6";

const Contact = () => {

  return (
    <div className="bodyContentTop">
      <NavbarTop />
      <div className='content'>
        <br></br>

        <div className='contactDetail'>
          <h2>Contact Us</h2>

          <div className='contactFlex'>
            <div className='contactLeft'>
              <h3>CONTACT INFO</h3>
              <p><Link to="mailto:info@arconfm.com" rel="noopener noreferrer"><p><b>Email:</b> info@arconqatar.com</p> </Link></p>
              <p><Link to="tel:+974 4469 6902" rel="noopener noreferrer"><p><b>Tel:</b> +974 4469 6902</p> </Link></p>
              <p><Link to="+974-44696901 ;type=fax" rel="noopener noreferrer"><p><b>Fax:</b> +974-44696901</p> </Link></p>

              <br></br><br></br>

              <h3>OPENING HOURS</h3>
              <p><b>Saturday - Wednesday:</b> 8:00 AM - 5:00 PM </p>
              <p><b>Thurday:</b> 8:00 AM - 1:00 PM</p>
              <p><b>Friday:</b> Closed</p>

              <br></br><br></br>

              <h3>FOLLOW US</h3>
              <div className="contactFollowflex">
                <p><Link to="#" rel="noopener noreferrer"> <p><FaLinkedin size={25}> </FaLinkedin></p> </Link></p>
                <p><Link to="#" rel="noopener noreferrer"> <p><FaFacebook size={25}></FaFacebook></p> </Link></p>
                <p><Link to="#" rel="noopener noreferrer"> <p><FaInstagram size={25}></FaInstagram></p> </Link></p>
                <p><Link to="#" rel="noopener noreferrer"> <p><FaXTwitter size={25}></FaXTwitter></p> </Link></p>
              </div>


            </div>

            <div className='contactRight'>
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d115473.5908469485!2d51.475931!3d25.252087!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45da86992f25b1%3A0x773dedb6b35cf273!2sArab%20United%20Construction%20W.L.L.%20(Arcon)!5e0!3m2!1sen!2sqa!4v1709098612463!5m2!1sen!2sqa"
                width="100%"
                height="300px"
                allowfullscreenLoading="lazy"
                referrerpolicy="no-referrer-when-downgrade">
              </iframe>
            </div>
          </div>


          <div contact>

          </div>

        </div>

      </div>

      <Footer />
    </div>
  );
};

export default Contact;