import React from "react";

import "./ProjectDetailStyles.css";
import "../components/ResponsiveStyles.css";

import NavbarTop from "../components/NavbarTop";
import Footer from "../components/Footer";

import HamadEducation from "../assets/projectsPics/HamadEducationCenter.png";

const HamadEducationCenter = () => {
  return (
    <div className="bodyContentTop">
      <NavbarTop />
      <div className="content">
        <div className="contentDetail">
          <h2>Hamad Education Center</h2>
          <img src={HamadEducation} alt="" />

          <div className="boxFlexDetail">

            <div className="boxDetail">
              <h4>Client</h4>
              <p>Hamad Medical Corporation (HMC)</p>
            </div>

            <div className="boxDetail">
              <h4>Location</h4>
              <p>Doha, Qatar</p>
            </div>

            <div className="boxDetail">
              <h4>Completion Date</h4>
              <p>October 2006</p>
            </div>

            <div className="boxDetail">
              <h4>Contract Value</h4>
              <p>12,756,985.00 QAR</p>
            </div>

          </div>

          <div className="boxDetailS">
            <h4>Scope of Work</h4>
            <p>Construction of 1 no. education center with 2 floors with decorative grill fencing; including all the relevant structural, architectural, interior finishes, MEP installation and external services</p>
          </div>

        </div>
      </div>

      <Footer />
    </div>
  );
};

export default HamadEducationCenter;