
import "./NavbarTopStyles.css";
import "./ResponsiveStyles.css";

import React, { useState } from "react";
import LogoImg from "../assets/ArconLogo.png";

import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { FaLinkedin, FaFacebook, FaInstagram, FaXTwitter  } from "react-icons/fa6";

const NavbarTop = () => {


    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);


    return (
        <div className="nav-containerTop">
            <div className="header-containerTop">
                <Link to="/">
                    <div className="logoTop">
                        <img className="logo-imgTop" src={LogoImg} alt="LogoImg" />
                    </div>
                </Link>
                <ul className={click ? "nav-menuTop active" : "nav-menuTop"}>
  
                    <li>
                        <a><Link to="/">HOME</Link></a>
                    </li>

                    <li>
                        <a><Link to="/About">ABOUT</Link></a>
                    </li>

                    <li>
                        <a><Link to="/Projects">PROJECTS</Link></a>
                    </li>

                    <li>
                        <a><Link to="/Contact">CONTACT</Link></a>
                    </li>

                    <div className="NavFollowTop">
                        <div className="NavFollowflexTop">
                        <a><FaLinkedin size={20}> </FaLinkedin></a>
                        <a><FaFacebook size={20}></FaFacebook></a>
                        <a><FaInstagram size={20}></FaInstagram></a>
                        <a><FaXTwitter size={20}></FaXTwitter></a>
                        </div>
                    </div>
                </ul>

                <div className="hamburgerTop" onClick={handleClick}>
                    {click ? (
                        <FaTimes className="menuBTNcloseTop" />
                    ) : (
                        <FaBars className="menuBTNopenTop" />
                    )}
                </div>
            </div>


        </div>

    );
};

export default NavbarTop;
