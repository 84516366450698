import React from "react";

import "./ProjectDetailStyles.css";
import "../components/ResponsiveStyles.css";

import { ImageViewer } from "react-image-viewer-dv"

import NavbarTop from "../components/NavbarTop";
import Footer from "../components/Footer";

import SixResidentalBuild from "../assets/projectsPics/ResidentalBuilding/1.jpg";
import SixResidentalBuild2 from "../assets/projectsPics/ResidentalBuilding/2.jpg";
import SixResidentalBuild3 from "../assets/projectsPics/ResidentalBuilding/3.jpg";
import SixResidentalBuild4 from "../assets/projectsPics/ResidentalBuilding/4.jpg";


const SixResidentalBuilding = () => {

  return (
    <div className="bodyContentTop">
      <NavbarTop />
      <div className="content">
        <div className="contentDetail">
          <h2>6 Residental Building</h2>
          <img src={SixResidentalBuild} alt="" />

          <div className="boxFlexDetail">

            <div className="boxDetail">
              <h4>Client</h4>
              <p>Asuol International</p>
            </div>

            <div className="boxDetail">
              <h4>Location</h4>
              <p>Al Sadd, Qatar</p>
            </div>

            <div className="boxDetail">
              <h4>Completion Date</h4>
              <p>January 2014</p>
            </div>

            <div className="boxDetail">
              <h4>Contract Value</h4>
              <p>91,015,600.00 QAR</p>
            </div>

          </div>

        </div>
        <div className="homeTitle2">
          <h2>Gallery</h2>
        </div>
        <div className="galleryView">
          <ImageViewer>
            <img src={SixResidentalBuild} alt="1" />
          </ImageViewer>
          <ImageViewer>
            <img src={SixResidentalBuild2} alt="2" />
          </ImageViewer>
          <ImageViewer>
            <img src={SixResidentalBuild3} alt="3" />
          </ImageViewer>
          <ImageViewer>
            <img src={SixResidentalBuild4} alt="4" />
          </ImageViewer>
        </div>


      </div>

      <Footer />
    </div>
  );
};

export default SixResidentalBuilding;