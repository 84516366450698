import React from "react";
import { Link } from "react-router-dom";

import {
    FaPhone,
    FaFax,
    FaMailBulk,

} from "react-icons/fa";

import { FaLinkedin, FaFacebook, FaInstagram, FaXTwitter } from "react-icons/fa6";


import { ImLocation } from "react-icons/im";
import "./FooterStyles.css";

const Footer = () => {


    return (

        <div className="footer">
            <div className="content">

                <div className="footerContentFlex">
                    <div className="flexLeft">
                        <p><Link to="mailto:info@arconfm.com" rel="noopener noreferrer"> <p><FaMailBulk size={14} /> info@arconqatar.com</p> </Link></p>
                        <p><Link to="https://goo.gl/maps/SSSnPqu8mCo15CJy9" target="_blank" rel="noopener noreferrer"> <p><ImLocation size={14} /> P.O. Box: 22087 Doha, Qatar</p> </Link></p>
                        <p><Link to="tel:+974 4469 6902" rel="noopener noreferrer"> <p><FaPhone size={14} /> +974 4469 6902</p> </Link></p>
                        <p><Link to="tel:+974 4469 6901 ;type=fax" rel="noopener noreferrer"> <p><FaFax size={14} /> +974 4469 6901</p> </Link></p>
                    </div>

                    <div className="flexRight">
                        <p>Stay updated about ARCON</p>
                        <div className="footerFollowflex">
                            <p><Link to="#" rel="noopener noreferrer"> <p><FaLinkedin size={20}> </FaLinkedin></p> </Link></p>
                            <p><Link to="#" rel="noopener noreferrer"> <p><FaFacebook size={20}></FaFacebook></p> </Link></p>
                            <p><Link to="#" rel="noopener noreferrer"> <p><FaInstagram size={20}></FaInstagram></p> </Link></p>
                            <p><Link to="#" rel="noopener noreferrer"> <p><FaXTwitter size={20}></FaXTwitter></p> </Link></p>
                        </div>
                    </div>

                </div>

                <div className="container">
                    <div className="btmFoooter">
                        <p>© 2024 ARCON. All rights reserved.</p>
                    </div>
                </div>

            </div>



        </div>





    );
};

export default Footer;
