import React from "react";

import "./ProjectDetailStyles.css";
import "../components/ResponsiveStyles.css";

import NavbarTop from "../components/NavbarTop";
import Footer from "../components/Footer";

import Vil from "../assets/projectsPics/Villas.png";

const Villas = () => {
  return (
    <div className="bodyContentTop">
      <NavbarTop />
      <div className="content">
        <div className="contentDetail">
          <h2>24 Villas</h2>
          <img src={Vil} alt="" />

          <div className="boxFlexDetail">

            <div className="boxDetail">
              <h4>Client</h4>
              <p>Shaikha Mariyam Mohd. Abdullah Al Thani</p>
            </div>

            <div className="boxDetail">
              <h4>Location</h4>
              <p>Al Rayyan, Qatar</p>
            </div>

            <div className="boxDetail">
              <h4>Completion Date</h4>
              <p>July 2005</p>
            </div>

            <div className="boxDetail">
              <h4>Contract Value</h4>
              <p>18,845,564.00 QAR</p>
            </div>

          </div>

          <div className="boxDetailS">
            <h4>Scope of Work</h4>
            <p>Construction of 24 Villas with 2 Floor each with block work compound wall; including all the relevant structural, architectural, interior finishes, MEP installation and external services</p>
          </div>

        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Villas;