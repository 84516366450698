import React from "react";
import { Link } from "react-router-dom";
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import "./HomeStyles.css";

import { IoChevronForward } from "react-icons/io5";

import Navbar from "./components/Navbar";
import HeroSliderHome from "./components/HeroSliderHome";
import Footer from "./components/Footer";

import TopRespon from "./components/TopRespon";

import ArconLogoInvertedFade from "./assets/ArconLogoInvertedFade.png";
import contruction from "./assets/contruction.jpg";


import Newton from "./assets/projectsPics/NewtonSchool/1.jpg";
import Court from "./assets/projectsPics/CourtofAppeal.jpg";
import Mic from "./assets/projectsPics/MicBusiness.jpg";
import Dental from "./assets/projectsPics/DentalClinics.jpg";
import Lab from "./assets/projectsPics/Laboratory.png";
import Multi from "./assets/projectsPics/Multi-Storey.png";
import School from "./assets/projectsPics/schoolgirl.png";
import Township from "./assets/projectsPics/DukhanTownship.png";
import Emergncy from "./assets/projectsPics/EmergncyHMC.png";
import LusailDev from "./assets/projectsPics/LusailDevelopment.jpg";
import AlWajba from "./assets/projectsPics/PrivateVillaAlWajba.png";
import Vil from "./assets/projectsPics/Villas.png";
import Diwan from "./assets/projectsPics/DiwanAmiriLibrary.jpg";


import AlHuda from "./assets/SisterCompanies/AlHuda.png";
import Alurays from "./assets/SisterCompanies/Alurays.png";
import ArconFm from "./assets/SisterCompanies/ArconFm.png";
import Echelle from "./assets/SisterCompanies/Echelle.jpg";
import Horizon from "./assets/SisterCompanies/Horizon.png";
import MEP from "./assets/SisterCompanies/MEP.jpg";
import Retro from "./assets/SisterCompanies/Retro.png";
import Watania from "./assets/SisterCompanies/Watania.jpg";
import WavesTrading from "./assets/SisterCompanies/WavesTrading.png";

import ArconVideo from "./assets/ArconVideo.mp4";

const Home = () => {

  return (
    <div className="bodyContent">

      <Navbar />
      <HeroSliderHome />


      <div className="contentFlex">
        <div className="containerFlexImg">
          <img src={ArconLogoInvertedFade} alt="" />
        </div>

        <div className="containerFlex">
          <h1>Welcome to ARCON</h1>

          <h4>Committed to fulfill the construction needs of booming Qatar</h4>

          <p>Arcon came into force in the year of 2001 and it was formerly known as ‘Gulf Falcon Engineering Company’ since 1991. It is my great pleasure to speak about the marvelous growth and achievement of Arcon within its inception of 18 years! We succeeded to multi fold our turnover with an increase of 150% comparing it with the previous years.</p>

          <p>The orientation of Arcon was with an induction of an ardent team of highly qualified and experienced personnel with an object to carry out the civil construction works especially for buildings and related infrastructure, but progressively with an augmentation of more personnel and an enthusiasm to enter into other areas, our efforts has been fructified well and with a remarkable experience and thrill we spread our wings into the augmentation of more personnel and an enthusiasm to enter into other areas, our efforts has been fructified well and with a remarkable experience and thrill we spread our wings into the other areas such as Petrochemical Field, Power Plants, Special projects, Road works, Street Lighting etc.</p>

          <p>With the existing infrastructure, facilities and incredible support from our talented Officials with a team of wholehearted employees for each area of our operations, it has now become viable for us to take up any challenging job in the construction field and its successful execution while meeting the client’s requirements.</p>
          <br></br><br></br>
          <p><Link to="/About" className="bottomLEARNMORE">LEARN MORE </Link></p>

        </div>
      </div>

      <div className="contentFlexIMG">
        <div className="containerFlexLeft">
          <h1>Relaiable<br />
            Project<br />
            Excecution<br />
          </h1>
        </div>

        <div className="containerFlexRight">
          <img src={contruction} alt="" />
        </div>

      </div>

      <div className="content">

        <div className="homeTitle">
          <h2>Featured Projects</h2>
          <Link to="/Projects"><h5>More Projects <IoChevronForward size={10}> </IoChevronForward > </h5></Link>
        </div>

        <div class="containerGrid">

          <Link to="/Projects/NewtonSchool" className="" >
            <div className="box">
              <img src={Newton} alt="1" />
              <div className="boxText">
                <h3>Newton School</h3>
                <h6>Doha, Qatar</h6>
              </div>
            </div>
          </Link>

          <Link to="/Projects/CourtBuilding" className="vertical" >
            <div className="box">
              <img src={Court} alt="2" />
              <div className="boxText">
                <h3>Court Building</h3>
                <h6>Lusail, Qatar</h6>
              </div>
            </div>
          </Link>

          <Link to="/Projects/MicBusiness" className="horizontal" >
            <div className="box">
              <img src={Mic} alt="4" />
              <div className="boxText">
                <h3>Mic Business & Recreation Complex</h3>
                <h6>Doha, Qatar</h6>
              </div>
            </div>
          </Link>

          <Link to="/Projects/DentalClinics" className="vertical" >
            <div className="box">
              <img src={Dental} alt="7" />
              <div className="boxText">
                <h3>Dental Clinics</h3>
                <h6>Doha, Qatar</h6>
              </div>
            </div>
          </Link>

          <Link to="/Projects/Laboratory" className="big" >
            <div className="box">
              <img src={Lab} alt="8" />
              <div className="boxText">
                <h3>Laboratories</h3>
                <h6>Doha, Qatar</h6>
              </div>
            </div>
          </Link>

          <Link to="/Projects/MultiStorey" className="vertical" >
            <div className="box">
              <img src={Multi} alt="9" />
              <div className="boxText">
                <h3>Multi-Storey Residential Complex</h3>
                <h6>Doha, Qatar</h6>
              </div>
            </div>
          </Link>

          <Link to="/Projects/SchoolGirl" className="horizontal" >
            <div className="box">
              <img src={School} alt="10" />
              <div className="boxText">
                <h3>Al Matar Preparatory School for Girls</h3>
                <h6>Doha, Qatar</h6>
              </div>
            </div>
          </Link>

          <Link to="/Projects/DukhanTownship" className="vertical" >
            <div className="box">
              <img src={Township} alt="12" />
              <div className="boxText">
                <h3>Dukhan Township & Boat</h3>
                <h6>Doha, Qatar</h6>
              </div>
            </div>
          </Link>

          <Link to="/Projects/EmergncyHMC" className="big" >
            <div className="box">
              <img src={Emergncy} alt="13" />
              <div className="boxText">
                <h3>Emergency Medical Services & Health Center</h3>
                <h6>Doha, Qatar</h6>
              </div>
            </div>
          </Link>

          <Link to="/Projects/LusailDevelopment" className="" >
            <div className="box">
              <img src={LusailDev} alt="14" />
              <div className="boxText">
                <h3>Lusail Development - Visitor Centre</h3>
                <h6>Lusail, Qatar</h6>
              </div>
            </div>
          </Link>

          <Link to="/Projects/PrivateVillaAlWajba" className="horizontal" >
            <div className="box">
              <img src={AlWajba} alt="16" />
              <div className="boxText">
                <h3>Private Villa</h3>
                <h6>Doha, Qatar</h6>
              </div>
            </div>
          </Link>

          <Link to="/Projects/Villas" className="" >
            <div className="box">
              <img src={Vil} alt="17" />
              <div className="boxText">
                <h3>24 Villas</h3>
                <h6>Doha, Qatar</h6>
              </div>
            </div>
          </Link>

          <Link to="/Projects/DiwanAmiriLibrary" className="vertical" >
            <div className="box">
              <img src={Diwan} alt="18" />
              <div className="boxText">
                <h3>Diwan Amiri Library</h3>
                <h6>Doha, Qatar</h6>
              </div>
            </div>
          </Link>

        </div>

      </div>

      <div className="content">

        <div className="homeTitle2">
          <h2>Project Video</h2>
        </div>
        <div className="videoContent">
          <Video
            controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
            onCanPlayThrough={() => {
              // Do stuff
            }}>
            <source src={ArconVideo} type="video/webm" />
          </Video>
        </div>

      </div>

      <div className="content">

        <div className="homeTitle2">
          <h2>Sister Companies</h2>
        </div>

        <div class="gridCompanies">

          <Link to="https://alhudaengineering.com" class="gridCompaniesbox" target="_blank" rel="noopener noreferrer"><img src={AlHuda} alt="" /></Link>

          <Link to="https://alurays.me" class="gridCompaniesbox" target="_blank" rel="noopener noreferrer"><img src={Alurays} alt="" /></Link>

          <Link to="https://arconfm.com" class="gridCompaniesbox" target="_blank" rel="noopener noreferrer"><img src={ArconFm} alt="" /></Link>

          <Link to="https://echelleinteriors.com" class="gridCompaniesbox" target="_blank" rel="noopener noreferrer"><img src={Echelle} alt="" /></Link>

          <Link to="https://hagqatar.net" class="gridCompaniesbox" target="_blank" rel="noopener noreferrer"><img src={Horizon} alt="" /></Link>

          <Link to="https://mepeqatar.com" class="gridCompaniesbox" target="_blank" rel="noopener noreferrer"><img src={MEP} alt="" /></Link>

          <Link to="https://retro.qa" class="gridCompaniesbox" target="_blank" rel="noopener noreferrer"><img src={Retro} alt="" /></Link>

          <Link to="https://wataniafire.com" class="gridCompaniesbox" target="_blank" rel="noopener noreferrer"><img src={Watania} alt="" /></Link>

          <Link to="https://waves-qatar.com" class="gridCompaniesbox" target="_blank" rel="noopener noreferrer"><img src={WavesTrading} alt="" /></Link>


        </div>

      </div>

      <Footer />
    </div>

  );
};

export default Home;