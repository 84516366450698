import React from "react";

import "./ProjectDetailStyles.css";
import "../components/ResponsiveStyles.css";

import { ImageViewer } from "react-image-viewer-dv"

import NavbarTop from "../components/NavbarTop";
import Footer from "../components/Footer";

import Newton1 from "../assets/projectsPics/NewtonSchool/1.jpg";
import Newton2 from "../assets/projectsPics/NewtonSchool/2.jpg";
import Newton3 from "../assets/projectsPics/NewtonSchool/3.jpg";
import Newton4 from "../assets/projectsPics/NewtonSchool/4.jpg";
import Newton5 from "../assets/projectsPics/NewtonSchool/5.jpg";
import Newton6 from "../assets/projectsPics/NewtonSchool/6.jpg";
import Newton7 from "../assets/projectsPics/NewtonSchool/7.jpg";
import Newton8 from "../assets/projectsPics/NewtonSchool/8.jpg";
import Newton9 from "../assets/projectsPics/NewtonSchool/9.jpg";
import Newton10 from "../assets/projectsPics/NewtonSchool/10.jpg";
import Newton11 from "../assets/projectsPics/NewtonSchool/11.jpg";
import Newton12 from "../assets/projectsPics/NewtonSchool/12.jpg";
import Newton13 from "../assets/projectsPics/NewtonSchool/13.jpg";
import Newton14 from "../assets/projectsPics/NewtonSchool/14.jpg";
import Newton15 from "../assets/projectsPics/NewtonSchool/15.jpg";

const NewtonSchool = () => {
  return (
    <div className="bodyContentTop">
      <NavbarTop />
      <div className="content">
        <div className="contentDetail">
          <h2>Newton School</h2>
          <img src={Newton1} alt="" />

          <div className="boxFlexDetail">

            <div className="boxDetail">
              <h4>Client</h4>
              <p>Smash Real Estate Development</p>
            </div>

            <div className="boxDetail">
              <h4>Location</h4>
              <p>Wadi Al Banat, Duhail, Qatar</p>
            </div>

            <div className="boxDetail">
              <h4>Completion Date</h4>
              <p>September 2020</p>
            </div>

            <div className="boxDetail">
              <h4>Contract Value</h4>
              <p>85,600,000.00 QAR</p>
            </div>

          </div>

          <div className="boxDetailS">
            <h4>Scope of Work</h4>
            <p>Construction of B+G+1+2+3 Newton School on Plot 70031000</p>
          </div>

        </div>

        <div className="homeTitle2">
          <h2>Gallery</h2>
        </div>
        <div className="galleryView">
          <ImageViewer>
              <img src={Newton2}  alt="1" />
            </ImageViewer>
            <ImageViewer>
              <img src={Newton3}  alt="2" />
            </ImageViewer>
            <ImageViewer>
              <img src={Newton4}  alt="3" />
            </ImageViewer>
            <ImageViewer>
              <img src={Newton5}  alt="4" />
            </ImageViewer>

            <ImageViewer>
              <img src={Newton6}  alt="5" />
            </ImageViewer>
            <ImageViewer>
              <img src={Newton7}  alt="6" />
            </ImageViewer>
            <ImageViewer>
              <img src={Newton8}  alt="7" />
            </ImageViewer>
            <ImageViewer>
              <img src={Newton9}  alt="8" />
            </ImageViewer>
            <ImageViewer>
              <img src={Newton10}  alt="9" />
            </ImageViewer>
            <ImageViewer>
              <img src={Newton11}  alt="10" />
            </ImageViewer>
            <ImageViewer>
              <img src={Newton12}  alt="11" />
            </ImageViewer>
            <ImageViewer>
              <img src={Newton13}  alt="12" />
            </ImageViewer>
            <ImageViewer>
              <img src={Newton14}  alt="13" />
            </ImageViewer>
            <ImageViewer>
              <img src={Newton15}  alt="14" />
            </ImageViewer>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default NewtonSchool;