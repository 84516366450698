import React from "react";
import { Link } from "react-router-dom";

import "./ProjectsStyles.css";
import "./components/ResponsiveStyles.css";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

import TopBgProj from "./assets/TopBgProj.jpg";


import Newton from "./assets/projectsPics/NewtonSchool/1.jpg";
import Court from "./assets/projectsPics/CourtofAppeal.jpg";
import parkingHMC from "./assets/projectsPics/CarParkingHMC.png";
import Mic from "./assets/projectsPics/MicBusiness.jpg";
import ABM from "./assets/projectsPics/ABMMilitary.png";
import Social from "./assets/projectsPics/Cultural&SocialCenter.png";
import Dental from "./assets/projectsPics/DentalClinics.jpg";
import Lab from "./assets/projectsPics/Laboratory.png";
import Multi from "./assets/projectsPics/Multi-Storey.png";
import School from "./assets/projectsPics/schoolgirl.png";
import UmSalal from "./assets/projectsPics/UmSalalSocial.png";
import Township from "./assets/projectsPics/DukhanTownship.png";
import Emergncy from "./assets/projectsPics/EmergncyHMC.png";
import LusailDev from "./assets/projectsPics/LusailDevelopment.jpg";
import HamadEducation from "./assets/projectsPics/HamadEducationCenter.png";
import AlWajba from "./assets/projectsPics/PrivateVillaAlWajba.png";
import Vil from "./assets/projectsPics/Villas.png";
import Diwan from "./assets/projectsPics/DiwanAmiriLibrary.jpg";
import SixBuilding from "./assets/projectsPics/ResidentalBuilding/1.jpg";


const Projects = () => {
  return (
    <div className="bodyContent">
      <Navbar />
      <div className="container">

        <div className="topBackground">
          <img src={TopBgProj} alt="" />
          <div className="content">
            <div className="pageTitle">
              <h1>Projects</h1>
            </div>
          </div>
        </div>

      </div>
      <div className="content">

        <div class="containerGrid">

        <Link to="/Projects/NewtonSchool" className="" >
            <div className="box">
              <img src={Newton} alt="1" />
              <div className="boxText">
                <h3>Newton School</h3>
                <h6>Doha, Qatar</h6>
              </div>
            </div>
          </Link>

          <Link to="/Projects/CourtBuilding" className="vertical" >
            <div className="box">
              <img src={Court} alt="2" />
              <div className="boxText">
                <h3>Court Building</h3>
                <h6>Lusail, Qatar</h6>
              </div>
            </div>
          </Link>

          <Link to="/Projects/CarParkingHMC" className="" >
            <div className="box">
              <img src={parkingHMC} alt="3" />
              <div className="boxText">
                <h3>Car Parking at HMC</h3>
                <h6>Doha, Qatar</h6>
              </div>
            </div>
          </Link>

          <Link to="/Projects/MicBusiness" className="horizontal" >
            <div className="box">
              <img src={Mic} alt="4" />
              <div className="boxText">
                <h3>Mic Business & Recreation Complex</h3>
                <h6>Doha, Qatar</h6>
              </div>
            </div>
          </Link>

          <Link to="/Projects/ABMMilitary" className="vertical" >
            <div className="box">
              <img src={ABM} alt="5" />
              <div className="boxText">
                <h3>ABM Military College</h3>
                <h6>Doha, Qatar</h6>
              </div>
            </div>
          </Link>

          <Link to="/Projects/CulturalSocialCenter" className="" >
            <div className="box">
              <img src={Social} alt="6" />
              <div className="boxText">
                <h3>Cultural and Social Center</h3>
                <h6>Doha, Qatar</h6>
              </div>
            </div>
          </Link>

          <Link to="/Projects/DentalClinics" className="vertical" >
            <div className="box">
              <img src={Dental} alt="7" />
              <div className="boxText">
                <h3>Dental Clinics</h3>
                <h6>Doha, Qatar</h6>
              </div>
            </div>
          </Link>

          <Link to="/Projects/Laboratory" className="big" >
            <div className="box">
              <img src={Lab} alt="8" />
              <div className="boxText">
                <h3>Laboratories</h3>
                <h6>Doha, Qatar</h6>
              </div>
            </div>
          </Link>

          <Link to="/Projects/MultiStorey" className="vertical" >
            <div className="box">
              <img src={Multi} alt="9" />
              <div className="boxText">
                <h3>Multi-Storey Residential Complex</h3>
                <h6>Doha, Qatar</h6>
              </div>
            </div>
          </Link>

          <Link to="/Projects/SchoolGirl" className="horizontal" >
            <div className="box">
              <img src={School} alt="10" />
              <div className="boxText">
                <h3>Al Matar Preparatory School for Girls</h3>
                <h6>Doha, Qatar</h6>
              </div>
            </div>
          </Link>

          <Link to="/Projects/UmSalalSocial" className="big" >
            <div className="box">
              <img src={UmSalal} alt="11" />
              <div className="boxText">
                <h3>Social & Cultural Centre</h3>
                <h6>Doha, Qatar</h6>
              </div>
            </div>
          </Link>

          <Link to="/Projects/DukhanTownship" className="vertical" >
            <div className="box">
              <img src={Township} alt="12" />
              <div className="boxText">
                <h3>Dukhan Township & Boat</h3>
                <h6>Doha, Qatar</h6>
              </div>
            </div>
          </Link>

          <Link to="/Projects/EmergncyHMC" className="big" >
            <div className="box">
              <img src={Emergncy} alt="13" />
              <div className="boxText">
                <h3>Emergency Medical Services & Health Center</h3>
                <h6>Doha, Qatar</h6>
              </div>
            </div>
          </Link>

          <Link to="/Projects/LusailDevelopment" className="" >
            <div className="box">
              <img src={LusailDev} alt="14" />
              <div className="boxText">
                <h3>Lusail Development - Visitor Centre</h3>
                <h6>Lusail, Qatar</h6>
              </div>
            </div>
          </Link>

          <Link to="/Projects/HamadEducationCenter" className="vertical" >
            <div className="box">
              <img src={HamadEducation} alt="15" />
              <div className="boxText">
                <h3>Hamad Education Center</h3>
                <h6>Doha, Qatar</h6>
              </div>
            </div>
          </Link>

          <Link to="/Projects/PrivateVillaAlWajba" className="horizontal" >
            <div className="box">
              <img src={AlWajba} alt="16" />
              <div className="boxText">
                <h3>Private Villa</h3>
                <h6>Doha, Qatar</h6>
              </div>
            </div>
          </Link>

          <Link to="/Projects/Villas" className="" >
            <div className="box">
              <img src={Vil} alt="17" />
              <div className="boxText">
                <h3>24 Villas</h3>
                <h6>Doha, Qatar</h6>
              </div>
            </div>
          </Link>

          <Link to="/Projects/DiwanAmiriLibrary" className="vertical" >
            <div className="box">
              <img src={Diwan} alt="18" />
              <div className="boxText">
                <h3>Diwan Amiri Library</h3>
                <h6>Doha, Qatar</h6>
              </div>
            </div>
          </Link>

          <Link to="/Projects/SixResidentalBuilding" className="horizontal" >
            <div className="box">
              <img src={SixBuilding} alt="18" />
              <div className="boxText">
                <h3>6 Residental Building</h3>
                <h6>Al Sadd, Qatar</h6>
              </div>
            </div>
          </Link>

        </div>



      </div>

      <Footer />
    </div>
  );
};

export default Projects;