import React from "react";

import "./ProjectDetailStyles.css";
import "../components/ResponsiveStyles.css";

import NavbarTop from "../components/NavbarTop";
import Footer from "../components/Footer";

import Diwan from "../assets/projectsPics/DiwanAmiriLibrary.jpg";

const DiwanAmiriLibrary = () => {
  return (
    <div className="bodyContentTop">
      <NavbarTop />
      <div className="content">
        <div className="contentDetail">
          <h2>Diwan Amiri Library</h2>
          <img src={Diwan} alt="" />

          <div className="boxFlexDetail">

            <div className="boxDetail">
              <h4>Client</h4>
              <p>Diwan Amiri</p>
            </div>

            <div className="boxDetail">
              <h4>Location</h4>
              <p>Doha, Qatar</p>
            </div>

            <div className="boxDetail">
              <h4>Completion Date</h4>
              <p>September 2003</p>
            </div>

            <div className="boxDetail">
              <h4>Contract Value</h4>
              <p>1,391,707.00 QAR</p>
            </div>

          </div>

          <div className="boxDetailS">
            <h4>Scope of Work</h4>
            <p>Renovation and Rehabilitation of Existing Diwan Amiri Library</p>
          </div>

        </div>
      </div>

      <Footer />
    </div>
  );
};

export default DiwanAmiriLibrary;