import React from "react";

import "./ProjectDetailStyles.css";
import "../components/ResponsiveStyles.css";

import NavbarTop from "../components/NavbarTop";
import Footer from "../components/Footer";

import Social from "../assets/projectsPics/Cultural&SocialCenter.png";

const CulturalSocialCenter = () => {
  return (
    <div className="bodyContentTop">
      <NavbarTop />
      <div className="content">
        <div className="contentDetail">
          <h2>Cultural and Social Center</h2>
          <img src={Social} alt="" />

          <div className="boxFlexDetail">

            <div className="boxDetail">
              <h4>Client</h4>
              <p>Qatar Olympic Committee</p>
            </div>

            <div className="boxDetail">
              <h4>Location</h4>
              <p>Al Dhakhira, Qatar</p>
            </div>

            <div className="boxDetail">
              <h4>Completion Date</h4>
              <p>April 2011</p>
            </div>

            <div className="boxDetail">
              <h4>Contract Value</h4>
              <p>44,441,170.00 QAR</p>
            </div>

          </div>

          <div className="boxDetailS">
            <h4>Scope of Work</h4>
            <p>Construction of 1 no. Social and Cultural Center comprising of Main Building with Sports Hall of Structural Steel Roofing, Staff Accommodation, Substation, Guard Rooms, Store Room, Tank Yard and External Services Infrastructure including football pitch/ playground for children, swimming pool, spanned in a plot area of 19,887m2; including all the required structural, architectural, interior finishes, MEP Installations, Soft and Hard Landscaping & Extern al Services</p>
          </div>

        </div>
      </div>

      <Footer />
    </div>
  );
};

export default CulturalSocialCenter;