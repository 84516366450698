import React from "react";

import "./ProjectDetailStyles.css";
import "../components/ResponsiveStyles.css";

import NavbarTop from "../components/NavbarTop";
import Footer from "../components/Footer";

import LusailDev from "../assets/projectsPics/LusailDevelopment.jpg";

const LusailDevelopment = () => {
  return (
    <div className="bodyContentTop">
      <NavbarTop />
      <div className="content">
        <div className="contentDetail">
          <h2>Lusail Development - Visitor Centre</h2>
          <img src={LusailDev} alt="" />

          <div className="boxFlexDetail">

            <div className="boxDetail">
              <h4>Client</h4>
              <p>Qatari Diar</p>
            </div>

            <div className="boxDetail">
              <h4>Location</h4>
              <p>Lusail, Qatar</p>
            </div>

            <div className="boxDetail">
              <h4>Completion Date</h4>
              <p>April 2007</p>
            </div>

            <div className="boxDetail">
              <h4>Contract Value</h4>
              <p>38,690,000.00 QAR</p>
            </div>

          </div>

          <div className="boxDetailS">
            <h4>Scope of Work</h4>
            <p>Construction of visitor's center with all the required facilities like auditorium hall, theater hall and others etc..) with boundary wall, external soft and landscaping; including all the structural, architectural, interior finishes, MEP installation and external services</p>
          </div>

        </div>
      </div>

      <Footer />
    </div>
  );
};

export default LusailDevelopment;