import React from "react";

import "./ProjectDetailStyles.css";
import "../components/ResponsiveStyles.css";

import NavbarTop from "../components/NavbarTop";
import Footer from "../components/Footer";

import Lab from "../assets/projectsPics/Laboratory.png";

const Laboratory = () => {
  return (
    <div className="bodyContentTop">
      <NavbarTop />
      <div className="content">
        <div className="contentDetail">
          <h2>Plant Tissue Culture Laboratory and Genetic Laboratory</h2>
          <img src={Lab} alt="" />

          <div className="boxFlexDetail">

            <div className="boxDetail">
              <h4>Client</h4>
              <p>ASHGHAL (PWA)</p>
            </div>

            <div className="boxDetail">
              <h4>Location</h4>
              <p>Abu Hamour, Qatar</p>
            </div>

            <div className="boxDetail">
              <h4>Completion Date</h4>
              <p>March 2010</p>
            </div>

            <div className="boxDetail">
              <h4>Contract Value</h4>
              <p>24,179,298.19 QAR</p>
            </div>

          </div>

          <div className="boxDetailS">
            <h4>Scope of Work</h4>
            <p>Construction of Plant Tissue Culture and Genetic Laboratory with Basement, Ground, First Floor and Penthouse, 1 No. Substation Building, 1 No. Generator Room and 61 Nos. Car Parking; including all the Relevant Structural, Architectural, Interior Finishes, MEP Installation Soft and Hard Landscaping and External Services</p>
          </div>

        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Laboratory;