import React from "react";

import "./ProjectDetailStyles.css";
import "../components/ResponsiveStyles.css";

import NavbarTop from "../components/NavbarTop";
import Footer from "../components/Footer";

import AlWajba from "../assets/projectsPics/PrivateVillaAlWajba.png";

const PrivateVillaAlWajba = () => {
  return (
    <div className="bodyContentTop">
      <NavbarTop />
      <div className="content">
        <div className="contentDetail">
          <h2>Private Villa</h2>
          <img src={AlWajba} alt="" />

          <div className="boxFlexDetail">

            <div className="boxDetail">
              <h4>Client</h4>
              <p>Private Engineering Office (PEO)</p>
            </div>

            <div className="boxDetail">
              <h4>Location</h4>
              <p>Al Wajbah, Qatar</p>
            </div>

            <div className="boxDetail">
              <h4>Completion Date</h4>
              <p>October 2006</p>
            </div>

            <div className="boxDetail">
              <h4>Contract Value</h4>
              <p>24,164,955.00 QAR</p>
            </div>

          </div>

          <div className="boxDetailS">
            <h4>Scope of Work</h4>
            <p>Demolition of the existing structure & construction of villa extension with B+G+M+Upper Mezzanine Floors with all structural, architectural, high quality interior finishes & all MEP works including external soft & hard landscaping plus construction of 2 nos. gazebo and precast concrete boundary wall</p>
          </div>

        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PrivateVillaAlWajba;