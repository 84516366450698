import React from "react";

import "./ProjectDetailStyles.css";
import "../components/ResponsiveStyles.css";

import NavbarTop from "../components/NavbarTop";
import Footer from "../components/Footer";

import School from "../assets/projectsPics/schoolgirl.png";

const SchoolGirl = () => {
  return (
    <div className="bodyContentTop">
      <NavbarTop />
      <div className="content">
        <div className="contentDetail">
          <h2>Al Matar Preparatory School for Girls</h2>
          <img src={School} alt="" />

          <div className="boxFlexDetail">

            <div className="boxDetail">
              <h4>Client</h4>
              <p>ASHGHAL (PWA)</p>
            </div>

            <div className="boxDetail">
              <h4>Location</h4>
              <p>Mesaimeer, Qatar</p>
            </div>

            <div className="boxDetail">
              <h4>Completion Date</h4>
              <p>May 2009</p>
            </div>

            <div className="boxDetail">
              <h4>Contract Value</h4>
              <p>29,128,061.27 QAR</p>
            </div>

          </div>

          <div className="boxDetailS">
            <h4>Scope of Work</h4>
            <p>Construction of Preparatory School Building for Girls (School No. 8) with Ground floor / Administration, Cafeteria, Assembly Hall,10No. Classrooms, Auditorium, and Multipurpose Hall, playground and sports facilities etc.; including precast boundary wall and all the structural, architectural, interior finishes, MEP installation, soft and hard landscaping & external services etc</p>
          </div>

        </div>
      </div>

      <Footer />
    </div>
  );
};

export default SchoolGirl;