import React from "react";

import "./ProjectDetailStyles.css";
import "../components/ResponsiveStyles.css";

import NavbarTop from "../components/NavbarTop";
import Footer from "../components/Footer";

import Mic from "../assets/projectsPics/MicBusiness.jpg";

const MicBusiness = () => {
  return (
    <div className="bodyContentTop">
      <NavbarTop />
      <div className="content">
        <div className="contentDetail">
          <h2>Mic Business and Recreation Complex</h2>
          <img src={Mic} alt="" />

          <div className="boxFlexDetail">

            <div className="boxDetail">
              <h4>Client</h4>
              <p>Al Aqaria / QP MIC</p>
            </div>

            <div className="boxDetail">
              <h4>Location</h4>
              <p>Mesaieed, Qatar</p>
            </div>

            <div className="boxDetail">
              <h4>Completion Date</h4>
              <p>2018</p>
            </div>

            <div className="boxDetail">
              <h4>Contract Value</h4>
              <p>210,000,000.00 QAR</p>
            </div>

          </div>

          <div className="boxDetailS">
            <h4>Scope of Work</h4>
            <p>The scope of this project comprises the procurement, construction, installation, testing and commissioning of the Main Recreational Club Building with associated amenity facilities and the infrastructure</p>
          </div>

        </div>
      </div>

      <Footer />
    </div>
  );
};

export default MicBusiness;