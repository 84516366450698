import React from "react";

import "./ProjectDetailStyles.css";
import "../components/ResponsiveStyles.css";

import NavbarTop from "../components/NavbarTop";
import Footer from "../components/Footer";

import ABM from "../assets/projectsPics/ABMMilitary.png";

const ABMMilitary = () => {
  return (
    <div className="bodyContentTop">
      <NavbarTop />
      <div className="content">
        <div className="contentDetail">
          <h2>ABM Military College</h2>
          <img src={ABM} alt="" />

          <div className="boxFlexDetail">

            <div className="boxDetail">
              <h4>Client</h4>
              <p>Qatar Armed Forces</p>
            </div>

            <div className="boxDetail">
              <h4>Location</h4>
              <p>Duhailiyat, Qatar</p>
            </div>

            <div className="boxDetail">
              <h4>Completion Date</h4>
              <p>February 2018</p>
            </div>

            <div className="boxDetail">
              <h4>Contract Value</h4>
              <p>13,500,525.00 QAR</p>
            </div>

          </div>

          <div className="boxDetailS">
            <h4>Scope of Work</h4>
            <p>Construction of Multipurpose Sports Hall with Steel Structure Roofing, Sub-Station Building and External Soft & Hard Landscaping; including all the relevant Structural, Architectural and Interior Finishes, MEP Installation and External Services</p>
          </div>

        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ABMMilitary;