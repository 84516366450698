import React from "react";

import "./ProjectDetailStyles.css";
import "../components/ResponsiveStyles.css";

import NavbarTop from "../components/NavbarTop";
import Footer from "../components/Footer";

import Township from "../assets/projectsPics/DukhanTownship.png";

const DukhanTownship = () => {
  return (
    <div className="bodyContentTop">
      <NavbarTop />
      <div className="content">
        <div className="contentDetail">
          <h2>Dukhan Township & Boat</h2>
          <img src={Township} alt="" />

          <div className="boxFlexDetail">

            <div className="boxDetail">
              <h4>Client</h4>
              <p>Qatar Petroleum</p>
            </div>

            <div className="boxDetail">
              <h4>Location</h4>
              <p>Dukhan, Qatar</p>
            </div>

            <div className="boxDetail">
              <h4>Completion Date</h4>
              <p>December 2008</p>
            </div>

            <div className="boxDetail">
              <h4>Contract Value</h4>
              <p>1,391,707.00 QAR</p>
            </div>

          </div>

          <div className="boxDetailS">
            <h4>Scope of Work</h4>
            <p>Designing and Constructing of 1 No. Recreation Center with Steel Structural Roofing; Including all the Structural, Architectural, Interior Finishes, MEP Installation and Soft and Hard landscaping</p>
          </div>

        </div>
      </div>

      <Footer />
    </div>
  );
};

export default DukhanTownship;