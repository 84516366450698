import React from "react";

import "./ProjectDetailStyles.css";
import "../components/ResponsiveStyles.css";

import NavbarTop from "../components/NavbarTop";
import Footer from "../components/Footer";
import TopRespon from "../components/TopRespon";

import Court from "../assets/projectsPics/CourtofAppeal.jpg";

const CourtBuilding = () => {
  return (
    <div className="bodyContentTop">
      <NavbarTop />
      <div className="content">
        <div className="contentDetail">
          <h2>Court Building</h2>
          <img src={Court} alt="" />

          <div className="boxFlexDetail">

            <div className="boxDetail">
              <h4>Client</h4>
              <p>Supreme Judiciary Council</p>
            </div>

            <div className="boxDetail">
              <h4>Location</h4>
              <p>Lusail, Qatar</p>
            </div>

            <div className="boxDetail">
              <h4>Completion Date</h4>
              <p>September 2019</p>
            </div>

            <div className="boxDetail">
              <h4>Contract Value</h4>
              <p>37,800,000.00 QAR</p>
            </div>

          </div>

          <div className="boxDetailS">
            <h4>Scope of Work</h4>
            <p>Execution of Architectural Finishing Interior Decoration and Electrical and Mechanical Work in Court Building</p>
          </div>

        </div>
      </div>

      <Footer />
    </div>
  );
};

export default CourtBuilding;