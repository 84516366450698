import React from "react";

import "./ProjectDetailStyles.css";
import "../components/ResponsiveStyles.css";

import NavbarTop from "../components/NavbarTop";
import Footer from "../components/Footer";

import parkingHMC from "../assets/projectsPics/CarParkingHMC.png";

const CarParkingHMC = () => {
  return (
    <div className="bodyContentTop">
      <NavbarTop />
      <div className="content">
        <div className="contentDetail">
          <h2>Car Parking at Hamad Medical City</h2>
          <img src={parkingHMC} alt="" />

          <div className="boxFlexDetail">

            <div className="boxDetail">
              <h4>Client</h4>
              <p>Hamad Medical Corporation</p>
            </div>

            <div className="boxDetail">
              <h4>Location</h4>
              <p>Doha, Qatar</p>
            </div>

            <div className="boxDetail">
              <h4>Completion Date</h4>
              <p>September 2019</p>
            </div>

            <div className="boxDetail">
              <h4>Contract Value</h4>
              <p>10,000,000.00 QAR</p>
            </div>

          </div>

          <div className="boxDetailS">
            <h4>Scope of Work</h4>
            <p>Design and build car park shade including (foundation, steel structure and waterproofed shade fabric) to match with the existing shades in Hamad Medical City. The approximate parking bays inside and outside the buildings will be 473 sqm including the 3-shuttle bus drop off</p>
          </div>

        </div>
      </div>

      <Footer />
    </div>
  );
};

export default CarParkingHMC;